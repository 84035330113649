import { defineStore } from "pinia";
import { useOrgStore } from "./org";
import User from "~/types/UserType";

type State = {
  user: User | null;
};

export const useUserStore = defineStore("user", {
  state: (): State => ({
    user: null,
  }),
  getters: {
    getUser(state) {
      return state.user;
    },
    allowSocialAccess(state) {
      const orgStore = useOrgStore();

      return orgStore.hasSocialTracking && state?.user?.social_listening_access;
    },
  },
  actions: {
    setUser(user: User) {
      this.user = user;
    },
  },
});
