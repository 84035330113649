import { defineStore } from "pinia";
import { useApiCall } from "../composables/useApiCall";
import {
  getExcludedDomainsRequestParams,
  getExcludedMentionsRequestParams,
} from "../utils/exclusionUtils";
import { useSearchStore } from "./search";

export const useExclusion = defineStore("exclusion", {
  state: () => ({
    pubs: [],
    excludedDomains: [],
    mentions: [],
  }),
  actions: {
    setPubs(pubs: any) {
      this.pubs = pubs;
    },
    setMentions(mentions: any) {
      this.mentions = mentions;
    },
    resetPubs() {
      this.pubs = [];
    },
    addExcludedDomain(excludedDomain: any) {
      this.excludedDomains.push(excludedDomain);
    },
    async excludePubs() {
      try {
        const responses = await Promise.all(
          this.pubs.map((pub) =>
            useApiCall(
              "/excluded_domains",
              getExcludedDomainsRequestParams(pub),
            ),
          ),
        );
        const isSuccess = responses.every(
          (response) => !response?.error?.value,
        );

        let errMsg = "manage.mentionsAndPublications.excludePublicationFailure";
        const errors = responses
          .filter((response) => response?.error?.value)
          .map((result) => {
            return result.error.value.cause.data.errors[0].code;
          });
        if (
          errors.includes(
            "you_have_reached_the_excluded_domain_limit_for_your_plan",
          )
        ) {
          errMsg =
            "manage.mentionsAndPublications.excludePublicationLimitError";
        }
      } catch (e) {
        console.log(`[exclusion.ts] excludePubs: ${e}`);
      } finally {
        this.resetPubs();
      }
    },
    async excludeMentions() {
      try {
        const responses = await Promise.all(
          this.mentions.map((mention) =>
            useApiCall(
              "/excluded_articles",
              getExcludedMentionsRequestParams(mention),
            ),
          ),
        );
        const isSuccess = responses.every(
          (response) => !response?.error?.value,
        );

        let errMsg = "manage.mentionsAndPublications.excludeFailure";
        const errors = responses
          .filter((response) => response?.error?.value)
          .map((result) => {
            return result.error.value.cause.data.errors[0].code;
          });
        if (
          errors.includes(
            "you_have_reached_the_excluded_domain_limit_for_your_plan",
          )
        ) {
          errMsg =
            "manage.mentionsAndPublications.excludePublicationLimitError";
        }

        if (isSuccess) {
          this.mentions.forEach((mention) => {
            this.addExcludedDomain({
              type: "excluded-articles",
              attributes: {
                author: mention.author,
                country: mention.country,
                created_at: mention.created_at,
                language: mention.language,
                media_type: mention.media_type,
                publication: mention.publication,
                published_on: mention.published_on,
                title: mention.title,
                url: mention.url,
              },
            });
          });
          return { isSuccess };
        } else {
          return { isSuccess: false, errMsg };
        }
      } catch (e) {
        console.log(`[exclusion.ts] excludeMentions: ${e}`);
      } finally {
        this.resetPubs();
      }
    },
    getPubsfromIds(ids: Set<string>) {
      const searchStore = useSearchStore();
      const idArrays = Array.from(ids);

      const filteredMentions = searchStore.mentions.filter((mention) =>
        idArrays.includes(mention.id),
      );

      const uniqueResults = new Set();
      const uniqueArray = [];

      for (const mention of filteredMentions) {
        const item = { media_type: mention.media_type, url: mention.domain };
        const key = `${item.media_type}-${item.url}`;

        if (!uniqueResults.has(key)) {
          uniqueResults.add(key);
          uniqueArray.push(item);
        }
      }

      return uniqueArray;
    },
    getMentionsfromIds(ids: Set<string>) {
      const searchStore = useSearchStore();
      const idArrays = Array.from(ids);
      const mentionArray = [];

      const filteredMentions = searchStore.mentions.filter((mention) =>
        idArrays.includes(mention.id),
      );

      for (const mention of filteredMentions) {
        const item = {
          author: mention.author,
          country: mention.country,
          media_type: mention.media_type,
          publication: mention.publication,
          published_on: mention.published_on,
          title: mention.title,
          url: mention.url,
        };

        mentionArray.push(item);
      }

      return mentionArray;
    },
  },
});
