export const getExcludedDomainsRequestParams = (publication: any) => {
  const currentSearchDashboard = JSON.parse(
    localStorage.getItem("currentSearchDashboard") as string,
  );
  const currentProfile = JSON.parse(
    localStorage.getItem("currentProfile") as string,
  );
  return {
    method: "POST",
    body: {
      data: {
        type: "excluded-domains",
        attributes: {
          media_type: publication.media_type,
          url: publication.url,
        },
        relationships: {
          profile: {
            data: {
              type: "profiles",
              id: currentProfile.data.id,
            },
          },
          search_dashboard: {
            data: {
              type: "search-dashboards",
              id: currentSearchDashboard.data.id,
            },
          },
        },
      },
    },
  };
};

export const getExcludedMentionsRequestParams = (mention: any) => {
  const currentProfile = JSON.parse(
    localStorage.getItem("currentProfile") as string,
  );
  return {
    method: "POST",
    body: {
      data: {
        type: "excluded-articles",
        attributes: {
          author: mention.author,
          country: mention.country,
          created_at: mention.created_at,
          language: mention.language,
          media_type: mention.media_type,
          publication: mention.publication,
          published_on: mention.published_on,
          title: mention.title,
          url: mention.url,
        },
        relationships: {
          profile: {
            data: {
              type: "profiles",
              id: currentProfile.data.id,
            },
          },
        },
      },
    },
  };
};
