import { defineStore } from "pinia";
import { useApiCall } from "~/composables/useApiCall";
import ManageState, {
  RequestedPublications,
  ExcludedPublications,
} from "~/types/ManageStateType";
import AddedMentionAttribute from "~/types/attributes/AddedMentionAttributeType";
import RequestOptions from "~/types/RequestOptionsType";

export const useManageStore = defineStore("manage", {
  state: (): ManageState => ({
    currentTab: "added-mentions",
    loading: false,
    searchText: "",
    sortBy: 1,
    orderBy: 1,
    page: 1,
    total: 0,
    perPage: 10,
    requestedPublications: [],
    addedMentions: [],
    excludedMentions: [],
    totalExcludedMentions: 0,
    newArticle: null,
    isSearchingArticle: false,
    articleSearchError: false,
    excludedPublications: [],
    excludeMentionErrorKey: "",
  }),
  getters: {
    pageStart: (state) => (state.page - 1) * state.perPage + 1,
    pageEnd: (state) =>
      state.page * state.perPage > state.total
        ? state.total
        : state.page * state.perPage,
    sortFields: (state) => {
      switch (state.currentTab) {
        case "added-mentions":
          return ["title", "publication", "media_type"];
        case "excluded-mentions":
          return ["name", "publication", "media_type"];
        case "requested-publications":
          return ["name", "media_type", "status"];
        case "excluded-publications":
          return ["name", "media_type", "status", "url"];
        default:
          return ["name"];
      }
    },
  },
  actions: {
    reset() {
      this.searchText = "";
      this.sortBy = 1;
      this.orderBy = 1;
      this.page = 1;
      this.total = 0;
    },
    updateSearchText(newText: string) {
      this.searchText = newText;
    },
    handleCloseAddedMentionsDrawer() {
      this.newArticle = null;
      this.articleSearchError = false;
    },
    handleSortChange(optionsArr: Array<number>) {
      this.sortBy = optionsArr[0];
      this.orderBy = optionsArr[1];
    },
    handlePaginateForward() {
      if (this.page * this.perPage < this.total) {
        this.page += 1;
      }
    },
    handlePaginateBackward() {
      if (this.pageStart !== 1) {
        this.page -= 1;
      }
    },

    filteredSortedFormattedResultPages(items: any) {
      // Sort the items based on the sort dropdown value
      let filteredItems = [];
      switch (this.sortBy) {
        case 1: // Date
          filteredItems = this.sortNewestToOldest(
            this.filteredResults(items, this.sortFields),
            "created_at",
          );
          break;
        case 2: // Alphabetical
          filteredItems = this.sortAlphabetical(
            this.filteredResults(items, this.sortFields),
            this.currentTab === "added-mentions" ? "publication" : "name",
          );
          break;
        default:
          filteredItems = this.filteredResults(items, this.sortFields);
      }

      // Put the items into pages based on the perPage value
      const rows = Math.ceil(filteredItems.length / this.perPage);
      const result = [];
      for (let i = 0; i < rows; i++) {
        result.push(
          filteredItems.slice(i * this.perPage, (i + 1) * this.perPage),
        );
      }

      return result;
    },

    // Filter items by the search term and fields
    filteredResults(items: any, fields: string[]) {
      if (!this.searchText) {
        return items;
      }

      // Normalize data because it could come in differently
      items = items.map((item) => {
        if (item.items) {
          const newItem = { ...item };

          item.items.forEach((item: any) => {
            if (item.label === "Media Type") {
              newItem.media_type = item.value;
            } else if (item.label === "Source") {
              newItem.publication = item.value;
            } else if (item.label === "Status") {
              newItem.status = item.value;
            } else if (item.label === "URL") {
              newItem.url = item.value;
            }
          });

          return newItem;
        } else {
          return item;
        }
      });

      return items.filter((item: any) => {
        return fields.some((field: string) => {
          return item[field]
            ?.toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
      });
    },

    filterMentionsByProfile(mentions: any[], profileId: any) {
      return mentions.filter(
        (mention) => mention.relationships.profile.data.id === profileId,
      );
    },

    sortAlphabetical(items: any[], field: string) {
      return items.sort((a, b) => {
        let returnValue = 0;
        const nameA = a[field]?.toLowerCase();
        const nameB = b[field]?.toLowerCase();

        if (nameA < nameB) {
          returnValue = -1;
        } else if (nameA > nameB) {
          returnValue = 1;
        } else {
          return 0;
        }
        return this.orderBy === 1 ? returnValue : returnValue * -1;
      });
    },

    sortNewestToOldest(items: any[], field: string) {
      return items.sort((a, b) => {
        const dateA: any = new Date(a[field]);
        const dateB: any = new Date(b[field]);
        return this.orderBy === 1 ? dateB - dateA : dateA - dateB;
      });
    },

    async fetchRequestedPublications() {
      this.loading = true;
      let pubs: RequestedPublications = [];
      try {
        const res = await useApiCall("/added_domains", {
          method: "GET",
        });
        if (res && !res.error.value) {
          pubs = res.data._rawValue.data;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        this.loading = false;
      }
      this.requestedPublications = pubs;
      this.total = pubs.length;
    },

    async fetchAddedMentions() {
      const currentProfile = JSON.parse(
        localStorage.getItem("currentProfile") || "{}",
      );
      const profileId = currentProfile.data?.id;
      this.loading = true;
      try {
        const res = await useApiCall(`/added_articles?profile_id=${profileId}`);
        if (res && !res.error.value) {
          this.addedMentions = res.data.value.data.map((el: any) => ({
            ...el.attributes,
            id: el.id,
          }));
          this.total = res.data.value.data.length;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async findArticle(url: string) {
      const date = new Date();
      const formattedDate =
        date.getFullYear() +
        `-` +
        (`${date.getMonth() + 1}`.length < 2
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`) +
        `-` +
        (`${date.getDate()}`.length < 2
          ? `0${date.getDate()}`
          : `${date.getDate()}`);
      const currentProfile = JSON.parse(
        localStorage.getItem("currentProfile") || "{}",
      );
      const profileId = currentProfile.data?.id;
      this.isSearchingArticle = true;
      this.articleSearchError = false;
      this.newArticle = null;
      try {
        const res = await useApiCall("/article_searches", {
          method: "POST",
          body: {
            data: {
              attributes: {
                filter_date_start: formattedDate,
                filter_date_end: formattedDate,
                url,
              },
              relationships: {
                profile: {
                  data: {
                    id: profileId.toString(),
                    type: "profiles",
                  },
                },
              },
              type: "article-searches",
            },
          },
        });
        if (res && !res.error.value) {
          const searchResult = res.data.value.data.attributes.search_result;
          this.newArticle = {
            ...searchResult,
            in_crawler: true,
            manual_edit: false,
          };
        } else {
          this.articleSearchError = true;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        this.articleSearchError = true;
      } finally {
        this.isSearchingArticle = false;
      }
    },

    async addMention() {
      let isArticleAdded = false;
      const currentProfile = JSON.parse(
        localStorage.getItem("currentProfile") || "{}",
      );
      const profileId = currentProfile.data?.id;

      const res = await useApiCall("/added_articles", {
        method: "POST",
        body: {
          data: {
            attributes: {
              author: this.newArticle?.author,
              country: this.newArticle?.country,
              created_at: this.newArticle?.created_at,
              in_crawler: this.newArticle?.in_crawler,
              lang: this.newArticle?.lang,
              manual_edit: this.newArticle?.manual_edit,
              media_type: this.newArticle?.media_type,
              publication: this.newArticle?.publication,
              published_on: this.newArticle?.published_on,
              summary: this.newArticle?.summary,
              title: this.newArticle?.title,
              url: this.newArticle?.url,
              licenses: this.newArticle?.licenses,
            },
            relationships: {
              profile: {
                data: {
                  id: profileId.toString(),
                  type: "profiles",
                },
              },
            },
            type: "added-articles",
          },
        },
      });

      if (res?.data?.value?.message === "countryLevelAccessFailed") {
        return "countryLevelAccessFailed";
      } else if (res?.data?.value?.message === "orgLicenseFailed") {
        return "orgLicenseFailed";
      } else if (res && !res.error.value) {
        isArticleAdded = true;
      } else if (
        String(res.error.value?.cause?.data?.errors[0]?.code).includes(
          "reached_the_added_article_limit",
        )
      ) {
        return "manage.mentionsAndPublications.addMentionLimitError";
      } else {
        return false;
      }

      this.fetchAddedMentions();
      return isArticleAdded;
    },

    async deleteAddedMention(id: string) {
      const options: RequestOptions = {
        method: "DELETE",
      };
      try {
        const resp = await useApiCall(`/added_articles/${id}`, options);

        // filter out the deleted addedMentions
        this.addedMentions = this.addedMentions.filter(
          (addedMention) => addedMention.id !== id,
        );
        this.total = this.addedMentions.length;
        return resp;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },

    async fetchExcludedPublication() {
      this.loading = true;
      let pubs: ExcludedPublications = [];
      try {
        const res = await useApiCall("/search_dashboards", {
          method: "GET",
        });
        if (res && !res.error.value) {
          pubs = res.data._rawValue.included.filter(
            (item: { type: string }) => item.type === "excluded_domain",
          );
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        this.loading = false;
      }
      this.excludedPublications = pubs;
      this.total = pubs.length;
    },

    async fetchExcludedMentions(id: string) {
      let mentions = [];
      try {
        const res = await useApiCall(`/excluded_articles?profile_id=${id}`, {
          method: "GET",
        });
        if (res && !res.error.value) {
          mentions = this.filterMentionsByProfile(res.data._rawValue.data, id);
          this.totalExcludedMentions =
            res.data._rawValue.meta.total_excluded_articles;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      this.excludedMentions = mentions;
      this.total = this.excludedMentions.length;
    },

    updateNewArticle(newArticle: AddedMentionAttribute) {
      this.newArticle = newArticle;
    },
  },
});
